import React from "react"
import Layout from "../components/layout"
import Brno_Map from "../images/brno-map.png"
import {Helmet} from "react-helmet/es/Helmet";

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            budget: 3000,
            name: "",
            email: "",
            phone: "",
            website: "",
            about: "",
            response: "",
            displayResult: false,
            displayClass: '',

        };
    }
    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name !== 'services') {
            this.setState({
                [name]: value,
            })
        }
    };

    handleCheckbox = event => {
        if (event.target.checked) {
            this.state.services.push(event.target.id);
        } else {
            for (let i = this.state.services.length - 1; i >= 0; i--) {
                if (this.state.services[i] === event.target.id) {
                    this.state.services.splice(i, 1);
                }
            }
        }

        this.setState({
            services: this.state.services,
        });
    };

    handleSubmit = event => {
        event.preventDefault();

        let HUBSPOT_CONTACT_FORM = 'https://api.hsforms.com/submissions/v3/integration/submit/6881310/17ba0bc5-cdb9-414d-8b21-c27686b8e77f';
        let data = {
            "submittedAt": new Date().getTime(),
            "fields": [
                {
                    "name": "email",
                    "value": this.state.email
                },
                {
                    "name": "firstname",
                    "value": this.state.name
                },
                {
                    "name": "phone",
                    "value": this.state.phone
                },
                {
                    "name": "website",
                    "value": this.state.website
                },
                {
                    "name": "budget",
                    "value": this.state.budget
                },
                {
                    "name": "services",
                    "value": this.state.services + ""
                },
                {
                    "name": "about",
                    "value": this.state.about
                }
            ],

            "legalConsentOptions": { // Include this object when GDPR options are enabled
                "consent": {
                    "consentToProcess": true,
                    "text": "I agree to allow Example Company to store and process my personal data.",
                    "communications": [
                        {
                            "value": true,
                            "subscriptionTypeId": 999,
                            "text": "I agree to receive marketing communications from Example Company."
                        }
                    ]
                }
            }
        };

        const final_data = JSON.stringify(data);

        // try {
          let _message = '';
            let _this = this;

            fetch(HUBSPOT_CONTACT_FORM, {
                    method: "post",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: final_data
                }
            ).then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    response.json().then(function (response) {
                        response.errors.forEach((data, index) => {
                          console.log(data.message,index);
                            //console.log(data, index);
                            if (_message === '') {
                                _message = data.message;
                            } else {
                                _message += '</br>' + data.message;
                            }
                        });
                        _this.setState({
                            response: _message,
                            displayResult: true,
                            displayClass: 'error-msg'
                        })
                        setTimeout(() => {
                            _this.setState({ displayResult: false });
                        }, 5000);
                    });
                    throw Error('Missing params');
                }
            }).then((response) => {
                _this.setState({
                    response: response.inlineMessage,
                    services: [],
                    budget: 3000,
                    name: "",
                    email: "",
                    phone: "",
                    website: "",
                    about: "",
                    displayResult: true,
                    displayClass: 'success-msg'
                })
                setTimeout(() => {
                    _this.setState({ displayResult: false });
                }, 5000);
            }).catch((error) => {
                console.error(error);
            })
        // } catch (e) {
        //     console.log(e);
        // }
    };

    render() {
        return <Layout>
            <Helmet>
                <title>
                    Contact Us | Get in Touch with Cueblocks </title>
                <meta name="viewport"
                      content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                <meta charSet="utf-8"/>
                <link rel="canonical" href="https://www.cueblocks.com/contact.php"/>
                <meta name="description"
                      content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
                <meta property="og:site_name" content="Cueblocks"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content="https://www.cueblocks.com/contact.php"/>
                <meta property="og:title" content="Contact Us | Get in Touch with Cueblocks"/>
                <meta property="og:description"
                      content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
                <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="Want to get in touch?  We'd love to hear from you. Contact us now by filling this form."/>
                <meta name="twitter:title" content="Contact Us | Get in Touch with Cueblocks"/>
                <meta name="twitter:site" content="@cueblocks"/>
                <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
            </Helmet>


            <section className="contact-service__row">
                <div className="container">
                    <div className="c-service__lt">
                        <h2 className="heading_main">We Want To Know More</h2>
                        <p>
                            In a dilemma? Don’t know where to begin?
                            <br/>
                            <br/>
                            We’ll help you get started! Just let us know what services interest you, and we’ll take it
                            from there.
                        </p>
                        <h6>You Ideate. We Create. </h6>
                    </div>
                    <div className="c-service__rt">
                        <div className="address__block">
                            <h4>we work from</h4>

                            <div className="adrs__block">
                                <h6>INDIA <span>/</span> CZECH REPUBLIC</h6>
                                <div className="add-block">

                                    <p>CHANDIGARH, IN</p>
                                    <figure>
                                        <svg
                                            version="1.1"
                                            width="142"
                                            height="140"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="chd__map"
                                            viewBox="160.20931036556038 142.95138948536768 2.780784030784016 3.159721029264663"
                                            preserveAspectRatio="xMinYMin"
                                        >
                                            <path
                                                fill="#88a4bc"
                                                stroke="#ffffff"
                                                d="M295.5,264.3L295.3,264.40000000000003L294.90000000000003,264.90000000000003L294.1,264.50000000000006L293.20000000000005,264.00000000000006L293.00000000000006,264.1000000000001L292.80000000000007,263.80000000000007L292.50000000000006,263.50000000000006L292.6000000000001,263.40000000000003L292.2000000000001,263.1L292.0000000000001,262.3L291.4000000000001,261.40000000000003L291.4000000000001,261.3L292.30000000000007,260.90000000000003L292.70000000000005,260.6L292.90000000000003,260.40000000000003L293.00000000000006,260.1L293.00000000000006,260L293.20000000000005,259.9L293.40000000000003,259.79999999999995L294.00000000000006,259.9L294.30000000000007,260.29999999999995L294.20000000000005,260.4L294.40000000000003,260.7L295.00000000000006,260.3L295.20000000000005,260.40000000000003L295.20000000000005,260.6L294.90000000000003,261L296.1,261.1L295.90000000000003,262.40000000000003L296.3,263.1L295.8,263.8L295.5,264.3Z"
                                                className="sm_state_6"
                                                opacity="1"
                                                strokeOpacity="1"
                                                strokeWidth="0.009469696969696918"
                                                strokeLinejoin="round"
                                                transform="matrix(0.5506,0,0,0.5506,0,0)"
                                                fillOpacity="1"
                                            ></path>
                                        </svg>
                                    </figure>
                                    <p>
                                        S.C.O 822, Second Floor <br/>
                                        NAC Mani Majra <br/>
                                        Chandigarh – 160101 <br/>
                                        +91–172–2739754
                                    </p>
                                </div>
                                <div className="add-block">
                                    <p>BRNO, CZ</p>
                                    <figure>
                                        <img src={Brno_Map} alt=""/>
                                    </figure>
                                    <p>
                                        Lidická 700/19
                                        <br/>
                                        Veveří
                                        <br/>
                                        Brno – 602 00
                                        <br/>
                                        +420 774-233-112
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-form__block">
                <div className="container">
                    <form onSubmit={this.handleSubmit}>
                        <div className="contact-form__row">
                            <div className="form__lt">
                                <h2 className="heading_main">SERVICES THAT INTEREST YOU </h2>


                                <div className="select-serrvice">
                                    <div className="checkbox__row">
                                        <input id="Magento" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Magento">
                                            <span></span>
                                            Magento
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Shopify" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Shopify">
                                            <span></span>
                                            Shopify
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Development" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Development">
                                            <span></span>
                                            Web Development
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Design" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Design">
                                            <span></span>
                                            Creative Design
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Organic" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Organic">
                                            <span></span>
                                            Organic Search
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Digital" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Digital">
                                            <span></span>
                                            Digital Advertising
                                        </label>
                                    </div>
                                    <div className="checkbox__row">
                                        <input id="Conversion" type="checkbox" onChange={this.handleCheckbox}/>
                                        <label htmlFor="Conversion">
                                            <span></span>
                                            Conversion
                                        </label>
                                    </div>
                                </div>
                                <div className="contact-budget__row">
                                    <div className="budget__block">
                                        <h3 className="heading_main">Budget</h3>
                                        <p>
                                            An upfront Budget estimate will help us ensure expectations are met.
                                            Ballparks are fine.
                                        </p>
                                        <div className="selecteurPrix">
                                            <div className="label__g label__m">
                                                <span className="range-value">{this.state.budget}</span>
                                            </div>
                                            <div className="range-slider">
                                                <input
                                                    className="input-range"
                                                    type="range"
                                                    defaultValue={this.state.budget}
                                                    onChange={(e) => {
                                                        this.setState({budget: e.target.value})
                                                    }}
                                                    min="3000"
                                                    max="50000"
                                                />
                                            </div>
                                            <div className="PrixMin PrixRange">$3K</div>
                                            <div className="PrixMax PrixRange">$50k+</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form__rt">
                                <h5>
                                    Get in touch with us and we’ll get back to you at the earliest. Your email will not
                                    go down the inbox
                                    abyss. It’s a promise! Or an old-fashioned phone call would work too
                                </h5>
                                <div className="form__row">
                                    <label htmlFor="name">Name<em>*</em></label>
                                    <input
                                        id="name"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Enter your name"
                                        required={true}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="border-line"></div>
                                </div>
                                <div className="form__row">
                                    <label htmlFor="email">Email<em>*</em></label>
                                    <input
                                        id="email"
                                        type="email"
                                        name="email"
                                        required={true}
                                        value={this.state.email}
                                        placeholder="Enter your email"
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="border-line"></div>
                                </div>
                                <div className="form__row">
                                    <label htmlFor="phone">Phone</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        name="phone"
                                        placeholder="+1- 1234- 567-678"
                                        value={this.state.phone}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="border-line"></div>
                                </div>
                                <div className="form__row">
                                    <label htmlFor="website">Website</label>
                                    <input
                                        id="website"
                                        type="text"
                                        name="website"
                                        value={this.state.website}
                                        placeholder="Enter your Website"
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="border-line"></div>
                                </div>
                                <div className="form__block2_lt">
                                    <h2>About the project<em>*</em></h2>
                                    <textarea name="about" id="" placeholder="Write here" value={this.state.about} required={true}
                                              onChange={this.handleInputChange}></textarea>
                                </div>
                                <div className="form__block2_rt">
                                    <h2>
                                        <span>Upload</span> any relevant file or doc required
                                    </h2>
                                    <div className="form__upload_col">
                                        <input type="file"/>
                                        <i>
                                            <svg
                                                width="84"
                                                height="84"
                                                data-name="Layer 1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 119.2 120.46">
                                                <path
                                                    d="M0,73.12H11.2c0,6.14,0,12.22,0,18.31,0,14.08,3.55,17.61,17.74,17.61q32.49,0,65,0c9.88,0,14.21-4.39,14.25-14.3,0-7.11,0-14.23,0-21.56h10.75c0,10,.78,19.83-.23,29.5-.93,8.91-9.9,16.19-19,17.32a60,60,0,0,1-7.47.44c-22.15,0-44.3.08-66.45,0C9.65,120.35.05,110.66,0,94.63,0,87.64,0,80.66,0,73.12Z"/>
                                                <path
                                                    d="M65.57,84H54.13V20.72L28.58,46.4,21,38.55,59.81,0c12.46,12.52,25.54,25.69,38.8,39l-7.8,6.92L65.57,20.34Z"/>
                                            </svg>
                                        </i>
                                        <p>Drop files here</p>
                                    </div>
                                </div>
                                <input type="submit" className="main_cta" value="Submit"/>
                                {this.state.displayResult ?
                                    <div className={this.state.displayClass} style={{ textAlign: 'center', marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: this.state.response }}></div> : ''
                                }

                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </Layout>
    }
}